import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/compliance-support/case-study",
    label: "CASE STUDY",
  },
]

const RiskReduction = () => (
  <Layout>
    <Seo title="CASE STUDY" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <div className="grid--bg--for-it-ops-csss">
      <section className="section-bio pt-5">
        <div className="container">
          <div className="row">
            <div className="pcsy">
              <div className="section-col-cs">
                <h4>CASE STUDY</h4>
                <hr />
              </div>
              <div className="col-sm-5">
                <h1>SHORTEN TIMEFRAMES &MINIMIZE COSTS</h1>
                <h3>VigilantMDR</h3>
                <p>
                  When security is done the Vigilant way it is done well – by
                  not only protecting your company from breaches – but also
                  supporting efficiency in other essential and money saving ways
                  inside your organization.
                </p>
                <p>
                  During a Vigilant Security Business Review (SBR), a component
                  of our MDR Service contract, we asked a client with government
                  contracts, “Are there any challenges your company is facing,
                  security, or non-security related?” It is a question we ask
                  all our clients, because Vigilant’s visibility into a company
                  is so expansive, often we can assist in unexpected places.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section className="section-blocks-6">
      <div className="section-col-1 pt-5">
        <div className="container">
          <div className="side-binder">
            <h4>PROBLEM</h4>
            <h3>AN OUTDATED SOLUTION</h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="csy">
          <p>
            “We have a reporting problem,” they explained, “the government is
            demanding a monthly report. To compile this report, it is taking one
            of our IT staff two weeks out of every month. It is costing us a
            fortune and the employee is now not doing what they were hired to
            do.”
          </p>
          <p>
            The massive report had to draw from disparate systems inside their
            environment. They didn’t know how to access it all in the right way,
            or with the correct prioritization, so the consolidation was being
            done manually.
          </p>
        </div>
      </div>
    </section>
    <section className="section-blocks-6">
      <div className="section-col-1 pt-5">
        <div className="container">
          <div className="side-binder">
            <h4>OUTCOME</h4>
            <h3>REPORTING SIMPLIFIED</h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="csy">
          <p>
            From the client's perspective this was a disparate and confounding
            problem, from Vigilant's perspective this was a data collection and
            consolidation problem... and we had the data.
          </p>
          <p>At Vigilant, we are problem solvers.</p>
          <p>
            Exceeding their SOPs, the Development Team found the bandwidth and
            designed a solution-based result: push a button, get a report. As a
            result, that employee’s workload on this government requirement went
            from two weeks per month to just a couple of minutes.
          </p>
          <p>
            When you work with Vigilant you get our whole-team-approach to
            security service. And that service is not a white-gloved butler,
            either. It is your gritty force, shoulder-to-shoulder with you in
            the trenches – as your trusted partner.
          </p>
        </div>
      </div>
    </section>

    <div className="text-center pb-5">
      <Link to="/contact/" className="nav-section--cta-btn">
        <div className="d-flex align-items-center">
          <span
            style={{ marginLeft: "25px" }}
            className="nav-section--cta-title pr-5"
          >
            schedule a call
          </span>
          <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
        </div>
      </Link>
    </div>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default RiskReduction
